import { Component, OnInit, ViewChild, EventEmitter, Output, Input, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-requests',
    templateUrl: './requests.component.html',
    styleUrl: './requests.component.css',
    standalone: false
})
export class RequestsComponent implements OnInit {

  constructor(private dataService: DataService, private cdr: ChangeDetectorRef, private route: ActivatedRoute) { }

  @Input() ItemsPerPage: number = 250; 
  @Input() AbsenceID: number = 0; //0=all

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  totalRecords = 0;
  dataMyTime = new MatTableDataSource<any>();
  UserID: number = 0;


  displayedColumns: string[] = ['RequestStatusDescription', 'AbsenceDescription', 'StartDateTime', 'EndDateTime', 'TotalHours', 'RequestDescription'];



  ngOnInit(): void {


    //if admin run the admin func

    this.route.queryParams.subscribe(params => {
      // Access query parameters here
      if (params['UserID']) {
        this.UserID = params['UserID'];    // Access the userId query parameter
      }
    });


    if (this.dataService.GlobalIsAdmin && this.UserID != 0) {
      //nothin
      this.dataService.dbAdminGetMyTime(this.UserID,this.AbsenceID).subscribe((response) => {
        this.dataMyTime.data = response.dsData;
        this.totalRecords = this.dataMyTime.data.length;
        this.cdr.detectChanges();
        this.dataMyTime.paginator = this.paginator;
      });

    } else {
      this.dataService.dbGetMyTime(this.AbsenceID).subscribe((response) => {
        this.dataMyTime.data = response.dsData;
        this.totalRecords = this.dataMyTime.data.length;
        this.cdr.detectChanges();
        this.dataMyTime.paginator = this.paginator;
      });

    }



  }





  onRowClick(row: any) {
    var RequestID: number = row.RequestID;
    this.dataService.OpenSidePanel({ requestID: RequestID, isApproving: false });
  }



}
