<div style="
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;margin-top: 50px;">
  <img src="https://asbcore.co.uk/assets/images/corewhite.png" style="margin-bottom:40px;">

  <h2 style="margin-bottom:40px;text-align:center;">Planned Maintenance in Progress</h2>
  <span style="text-align:center;">The system is currently undergoing planned maintenance.<br></span>
  <span style="margin-top:14px;text-align:center;">Please try again later or <a href="/">click here</a> to check if the logon page is available.</span>

  <span style="margin-top:40px;text-align:center;">Please contact your Line Manager or Supervisor if you have a question regarding absence.</span>

  <span style="margin-top:14px;text-align:center;">Please contact <a href="mailto:supportnotify@alsim.co.uk">Core Support</a> if you have a technical query.</span>
