import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { DataService } from '../data.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DlgApprovalComponent } from '../dlg-approval/dlg-approval.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'app-pendingapprovallist',
    templateUrl: './pendingapprovallist.component.html',
    styleUrl: './pendingapprovallist.component.css',
    standalone: false
})
export class PendingapprovallistComponent implements OnInit {

 // @Output() openSidenav = new EventEmitter<{ requestID: number, isApproving: boolean }>();

  constructor(private dataService: DataService, public dialog: MatDialog) { }

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  dataGetPendingApprovals = new MatTableDataSource<any>();

  displayedColumns: string[] = ['DisplayName', 'AbsenceDescription', 'StartDateTime', 'EndDateTime', 'TotalHours', 'RequestDescription'];


  ngOnInit(): void {

    //listen for event
    this.dataService.refreshPendingApprovalsListEvent$.subscribe(() => {
      this.refreshData();
    });



    this.refreshData();
  }

  refreshData(): void {
    this.dataService.dbAdminGetPendingApprovals().subscribe((response) => {
      this.dataGetPendingApprovals.data = response.dsData;
    });
  }

  ngAfterViewInit() {
    this.dataGetPendingApprovals.paginator = this.paginator;
  }


  onRowClick(row: any) {
    var RequestID: number = row.RequestID;


    this.dataService.OpenSidePanel({ requestID: RequestID, isApproving: true });



  }


}
