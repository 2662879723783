<div class="login-container {{dev}}" >
  <img src="assets/images/alsimlogo.png" class="login-logo">
  <mat-card class="login-card">
    <mat-card-header class="login-header">
      <mat-card-title class="title">Sign In</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()">

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" placeholder="Enter your username" #usernameInput required />
          <mat-icon matPrefix>person</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" placeholder="Enter your password" required />
          <mat-icon matPrefix>lock</mat-icon>
        </mat-form-field>
        <div style="color:red;">{{error}}</div>

        <div class="login-actions">
          <button mat-raised-button color="primary" [disabled]="loginForm.invalid">Login</button>
        </div>

      </form>
      <a class="forgot-password" [routerLink]="['/forgotpassword']">Forgot Password?</a>

      
    </mat-card-content>
  </mat-card>
</div>
