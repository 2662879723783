import { Component, Input} from '@angular/core';

@Component({
    selector: 'app-stamp',
    templateUrl: './stamp.component.html',
    styleUrl: './stamp.component.css',
    standalone: false
})
export class StampComponent {

  @Input() status: string = "";  // Data received from the parent



}
