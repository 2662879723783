<div style="background-color:black;height:60px;" *ngIf="isEditing===true">
  <h1>
    <div style=" display: flex; justify-content: space-between; margin-left: 20px; margin-right: 10px; margin-top: 10px;color:white;">
      <div>{{title}}</div>
      <button (click)="closeDialog()" mat-icon-button aria-label="close dialog" mat-dialog-close style="color: white;">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </h1>
</div>
<div *ngIf="isEditing===false">
  <h1>{{title}}</h1>
</div>

<div id="requesttime" class="scrollable-content2" style="background-color: #f2f4f8; ">

  <form [formGroup]="yourForm" (ngSubmit)="onSubmit()" style="background-color: transparent; box-shadow: none;">


    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">

      <mat-form-field *ngIf="isManaging" style="display: flex; min-width: 270px; width: fit-content; margin-right: 30px;" appearance="outline">
        <mat-label>Select Employee</mat-label>
        <mat-select [(value)]="SelectedUserID" formControlName="frmSelectedUserID">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let option of dataUsers" [value]="option.UserID">
            {{option.DisplayName}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field style="min-width:270px;width: fit-content;margin-right: 30px;" appearance="outline">
        <mat-label>Select Absence Type</mat-label>
        <mat-select [(value)]="SelectedAbsenceID" formControlName="frmSelectedAbsenceID">

          <mat-select-trigger>
            {{ selectedAbsenceDescription }}
          </mat-select-trigger>

          <mat-option>None</mat-option>
          <mat-option *ngFor="let option of dataRequestTypes" [value]="option.AbsenceID">
            <div style="display: flex; align-items: center; flex-direction: row; align-content: center; justify-content: flex-start;"><span style="margin-right:10px;" class="material-symbols-outlined">{{option.Icon}}</span>{{option.AbsenceDescription}}</div>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="yourForm.controls['frmSelectedAbsenceID'].hasError('required')">
          Required
        </mat-error>
      </mat-form-field>

    </div>


    <div *ngIf="isManaging && yourForm.controls['frmSelectedAbsenceID'].value==8" style="display: flex; flex-direction: row; flex-wrap: wrap;">

      <mat-form-field appearance="outline">
        <!--only on external site work-->
        <mat-label>Enquiry Number</mat-label>
        <input matInput
               type="text"
               formControlName="frmEnquiryNumber"
               maxlength="6"
               pattern="[0-9]*"
               inputmode="numeric"
               (input)="onEnquiryInputChange($event)">
      </mat-form-field>
      <div style="margin-bottom: 20px; border-radius: 4px; display: flex; flex-direction: column; justify-content: center; padding-left: 10px; padding-right: 10px; ">
        <div>{{AccountCode}}</div>
        <div>{{CompanyName}}</div>

      </div>

      <!--
  <mat-form-field *ngIf="isManaging && yourForm.controls['frmSelectedAbsenceID'].value==8" style="display: flex; min-width: 270px; width: fit-content; margin-right: 30px;" appearance="outline">
    <mat-label>Select Priority</mat-label>
    <mat-select [(value)]="SelectedPriority" formControlName="frmSelectedPriority">
      <mat-option>1</mat-option>
      <mat-option *ngFor="let option of dataPriority" [value]="option.Priority">
        {{option.Priority}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  -->


    </div>


    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
      <div style=" display: flex; flex-direction: column; flex-wrap: nowrap; margin-right: 30px; ">
        <label for="dateTimeFrom">Date and Time</label>



        <!--START DATE-->
        <mat-form-field style="width:160px;"  appearance="outline">
          <mat-label>Start Date</mat-label>
          <input (click)="startDate.open()" matInput [matDatepickerFilter]="FilterWeekends" [matDatepicker]="startDate" name="selectedStartDate" formControlName="frmStartDate" [min]="minStartDate" readonly>
          <mat-datepicker-toggle matIconSuffix [for]="startDate">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #startDate disabled="false"></mat-datepicker>
          <mat-error *ngIf="yourForm.controls['frmStartDate'].hasError('required') && yourForm.controls['frmStartDate'].touched">
            Start Date is required
          </mat-error>
        </mat-form-field>


        <div *ngIf="yourForm.controls['frmStartDate'].value && yourForm.controls['frmEndDate'].value && !datesAreEqual()">

          <mat-form-field style="width:160px;"  appearance="outline">
            <!--Start Duration-->
            <mat-label>Duration1</mat-label>
            <mat-select [(value)]="StartDuration" formControlName="StartDuration1">
              <mat-option *ngFor="let option of StartDurations" [value]="option.DurationID">
                {{option.DurationDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div *ngIf="StartDuration === 3">
            <!--custom-->
            <mat-form-field style="width: 120px; margin-right: 10px;" appearance="outline">
              <mat-label>Start Hour</mat-label>
              <mat-select formControlName="StartCustomHour1">
                <mat-option *ngFor="let option of dataStartHours" [value]="option.Hour">
                  {{option.Hour}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="yourForm.controls['StartDuration1'].value === 3">
                Hour Required
              </mat-error>
            </mat-form-field>
            <mat-form-field style="width: 130px;"  appearance="outline">
              <mat-label>Start Minute</mat-label>
              <mat-select formControlName="StartCustomMinute1">
                <mat-option *ngFor="let option of filteredStartMinutes" [value]="option.Minute">
                  {{option.Minute}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <br />

        <!--END DATE-->
        <mat-form-field style="width:160px;"  appearance="outline">
          <mat-label>End Date</mat-label>
          <input (click)="endDate.open()" matInput [matDatepicker]="endDate" [matDatepickerFilter]="FilterWeekends" name="selectedEndDate" formControlName="frmEndDate" [min]="minEndDate" readonly>
          <mat-datepicker-toggle matIconSuffix [for]="endDate">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #endDate disabled="false"></mat-datepicker>
          <mat-error *ngIf="yourForm.controls['frmEndDate'].hasError('required') && yourForm.controls['frmEndDate'].touched">
            End Date is required
          </mat-error>
        </mat-form-field>



        <div *ngIf="yourForm.controls['frmStartDate'].value && yourForm.controls['frmEndDate'].value && !datesAreEqual()">
          <mat-form-field style="width:160px;"  appearance="outline">
            <mat-label>Duration2</mat-label>
            <mat-select [(value)]="EndDuration" formControlName="EndDuration1">
              <mat-option *ngFor="let option of EndDurations" [value]="option.DurationID">
                {{option.DurationDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="EndDuration === 3">
            <!--custom-->
            <mat-form-field style="width: 120px; margin-right: 10px;" appearance="outline">
              <mat-label>End Hour2</mat-label>
              <mat-select formControlName="EndCustomHour1">
                <mat-option *ngFor="let option of dataEndHours" [value]="option.Hour">
                  {{option.Hour}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 130px;" appearance="outline">
              <mat-label>End Minute2</mat-label>
              <mat-select formControlName="EndCustomMinute1">
                <mat-option *ngFor="let option of filteredEndMinutes" [value]="option.Minute">
                  {{option.Minute}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <!--Same Day Durations-->
        <div *ngIf="datesAreEqual()">
          <mat-form-field style="width:160px;" appearance="outline">
            <mat-label>Duration same</mat-label>
            <mat-select [(value)]="EndDuration" formControlName="EndDuration1">
              <mat-option *ngFor="let option of SameDayDurations" [value]="option.DurationID">
                {{option.DurationDescription}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div *ngIf="EndDuration === 3">
            <!--custom-->
            <mat-form-field style="width: 120px; margin-right: 10px;" appearance="outline">
              <mat-label>Start Hour</mat-label>
              <mat-select formControlName="StartCustomHour1">
                <mat-option *ngFor="let option of dataStartHours" [value]="option.Hour">
                  {{option.Hour}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 130px;" appearance="outline">
              <mat-label>Start Minute</mat-label>
              <mat-select formControlName="StartCustomMinute1">
                <mat-option *ngFor="let option of filteredStartMinutes" [value]="option.Minute">
                  {{option.Minute}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field style="width: 120px; margin-right: 10px;" appearance="outline">
              <mat-label>End Hour</mat-label>
              <mat-select formControlName="EndCustomHour1">
                <mat-option *ngFor="let option of dataEndHours" [value]="option.Hour">
                  {{option.Hour}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 130px;" appearance="outline">
              <mat-label>End Minute</mat-label>
              <mat-select formControlName="EndCustomMinute1">
                <mat-option *ngFor="let option of filteredEndMinutes" [value]="option.Minute">
                  {{option.Minute}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div style=" flex: auto;">
        <label for="notes">Notes:</label>
        <mat-form-field style="width: 100%;height:100%;" appearance="outline">
          <mat-label>Enter any notes...</mat-label>
          <textarea #Notes matInput formControlName="Notes1" style="height:100%;"></textarea>
        </mat-form-field>
      </div>
    </div>

  </form>



  <div style=" margin: 20px; margin-top: 0;">
    <mat-checkbox [(ngModel)]="isAutoApproved" style="margin-bottom:10px;" *ngIf="isManaging">Auto Approve?</mat-checkbox>
    <div style=" margin-top: 20px; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-end; align-items: center; }">
      <div style="padding-right:10px;color:red;">{{submitDisabledReason}}</div>
      <button (click)="onSubmit()" type="submit" [disabled]="yourForm.invalid || isBusy || disableButton" value="Submit">
        <div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-around;">
          <mat-spinner *ngIf="isBusy" style="max-width:20px;max-height:20px;margin-right:10px;"></mat-spinner>Submit
        </div>
      </button>
    </div>
  </div>
  <br /><br /><br /><br /><br /><br />


</div>
