import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../environments/environment'; // Correct import path
import { BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DataService {


  private SidePanelOpen = new BehaviorSubject<void>(undefined);
  get SidePanelOpenEvent$() {
    return this.SidePanelOpen.asObservable();
  }
  OpenSidePanel(data: any) {
    this.SidePanelOpen.next(data);
  }



  private SidePanelClose = new BehaviorSubject<void>(undefined);
  get SidePanelOperationEvent$() {
    return this.SidePanelClose.asObservable();
  }
  CloseSidePanel() {
    this.SidePanelClose.next();
  }


  private refreshPendingApprovalsList = new BehaviorSubject<void>(undefined);
  get refreshPendingApprovalsListEvent$() {
    return this.refreshPendingApprovalsList.asObservable();
  }

  RefreshPendingApprovalsList() {
    this.refreshPendingApprovalsList.next();
  }


  private refreshPinboard = new BehaviorSubject<void>(undefined);
  get refreshPinboardEvent$() {
    return this.refreshPinboard.asObservable();
  }

  RefreshPinboard() {
    this.refreshPinboard.next();
  }




 





  constructor(private http: HttpClient) { }


  //globals
  GlobalUserID = 0;
  GlobalEmail = '';
  GlobalName = "";
  GlobalIsAdmin = 0;
  GlobalIsApprover = 0;

  apidomain = environment.apiUrl;

  headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  setHeaderNOTUSED(token:string):void {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }


 // https://alsimholidaysapi.centaurus-group.com/api/email/send-email
 //{"to": "andrew@meesey.com","subject": "Test Email from C# API via SendGrid","body": "This is a test email sent via SendGrid."}


  dbGetEnquiryDetails(EnquiryNumber: string): Observable<any> {
    const url = `https://devapi.asbcore.co.uk/api/data/GetEnquiryDetails?EnquiryNumber=${EnquiryNumber}`
    return this.http.get<any>(url, { headers: this.headers })
  }


  dbGetDateConflicts(RequestUserID: number, RequestStartDate: string, RequestEndDate: string, RequestAbsenceID: number, CurrentRequestID: number): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetDateConflicts?RequestUserID=${RequestUserID}&RequestStartDate=${RequestStartDate}&RequestEndDate=${RequestEndDate}&RequestAbsenceID=${RequestAbsenceID}&CurrentRequestID=${CurrentRequestID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetBalanceAfterApproved(RequestUserID: number, RequestStartDate: string, RequestEndDate: string, RequestStartDuration: number, RequestEndDuration: number, RequestStartCustomTime: string, RequestEndCustomTime: string, AbsenceID: number ): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetBalanceAfterApproved?RequestUserID=${RequestUserID}&RequestStartDate=${RequestStartDate}&RequestEndDate=${RequestEndDate}&RequestStartDuration=${RequestStartDuration}&RequestEndDuration=${RequestEndDuration}&RequestStartCustomTime=${RequestStartCustomTime}&RequestEndCustomTime=${RequestEndCustomTime}&AbsenceID=${AbsenceID}`;
    return this.http.get<any>(url, { headers: this.headers })
  }




  dbAdminGetMyTime(UserID: number,AbsenceID: number): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetMyTime?UserID=${UserID}&AbsenceID=${AbsenceID}`;
    return this.http.get<any>(url, { headers: this.headers })
  }


  dbAdminGetUsername(UserID: number): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetUsername?UserID=${UserID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }



  dbAdminGetBalancesForUser(UserID: number): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetBalancesForUser?UserID=${UserID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }



  dbResetPassword(Token: string, Email: string, Password: string): Observable<any> {
    const url = `${this.apidomain}/api/data/dbResetPassword?Token=${Token}&Email=${Email}&Password=${Password}`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbVerifyForgotToken(Token: string,Email: string): Observable<any> {
    const url = `${this.apidomain}/api/data/dbVerifyForgotToken?Token=${Token}&Email=${Email}`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbForgotPassword(Email: string): Observable<any> {
    const url = `${this.apidomain}/api/data/dbForgotPassword?Email=${Email}`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbAdminGetUserViewScope(UserID: number): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetUserViewScope?UserID=${UserID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetMonths(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetMonths`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetDepartments(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetDepartments`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbAdminGetAssignedUsers(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetAssignedUsers`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbGetApprovers(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetApprovers`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetPeriod(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetPeriod`;
    return this.http.get<any>(url, { headers: this.headers });
  }
  dbGetAllUsersPinboard(StartViewDate: string, EndViewDate: string,): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetAllUsersPinboard?StartViewDate=${StartViewDate}&EndViewDate=${EndViewDate}`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGeneratePinboard(StartViewDate: string, EndViewDate: string): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGeneratePinboard?StartViewDate=${StartViewDate}&EndViewDate=${EndViewDate}`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbGetBankHolidays(Year: string): Observable<any> {
     const url = `${this.apidomain}/api/data/dbGetBankHolidays?Year=${Year}`;
    //const url = `${this.apidomain}/api/data/dbGetBankHolidays?Year=2024`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbAdminSaveRequestResponse(data: any): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminSaveRequestResponse`;
    return this.http.post(url, data, { headers: this.headers });
  }

  dbAdminGetRequest(RequestID: number): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetRequest?RequestID=${RequestID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbAdminSaveUser(data: any): Observable<any> {
    console.log(data);
    const url = `${this.apidomain}/api/data/dbAdminSaveUser`;
    return this.http.post(url, data, { headers: this.headers });
  }

  dbAdminGetUser(UserID: number): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetUser?UserID=${UserID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbAdminGetPendingApprovals(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetPendingApprovals`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbAdminGetUsers(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetUsers`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbAdminGetUserHours(SelectedDate: string,UserID: string): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetUserHours?SelectedDate=${SelectedDate}&UserID=${UserID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbGetUserHours(SelectedDate : string): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetUserHours?SelectedDate=${SelectedDate}`;
    return this.http.get<any>(url, { headers: this.headers });
  }



  dbGetMinutes(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetMinutes`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetDurationTypes(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetDurationTypes`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbAdminGetAllAbsenceTypes(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbAdminGetAllAbsenceTypes`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetBalancesForUser(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetBalancesForUser`;
    return this.http.get<any>(url, { headers: this.headers });
  }


  dbGetAbsenceTypesForUser(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetAbsenceTypesForUser`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetAbsenceTypes(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetAbsenceTypes`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbGetAssignees(): Observable<any> {
    const url = `${this.apidomain}/api/data/dbGetAssignees`;
    return this.http.get<any>(url, { headers: this.headers });
  }

  dbSaveRequest(data: any): Observable<any> {
    const url = `${this.apidomain}/api/data/dbSaveRequest`;
    return this.http.post(url, data, { headers: this.headers });
  }

  dbGetMyTime(AbsenceID:number): Observable<any>{
    const url = `${this.apidomain}/api/data/dbGetMyTime?AbsenceID=${AbsenceID}`;
    return this.http.get<any>(url, { headers: this.headers });
  }

}
