import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-menusmall',
    templateUrl: './menusmall.component.html',
    styleUrl: './menusmall.component.css',
    standalone: false
})
export class MenusmallComponent {

}
