
<div class="pnlStaffcard">

  <div class="section">
    <div style="display: flex; align-items: center; flex-direction: row; justify-content: space-between;">
      <div class="title">{{DisplayName}}</div>


      <div *ngIf="isAdmin && (AllowCancel || AllowEdit)" class="actionsmenu" [matMenuTriggerFor]="actionsmenu">
        <mat-icon *ngIf="!isBusy">more_vert</mat-icon>
        <mat-spinner *ngIf="isBusy" style="max-width:20px;max-height:20px;"></mat-spinner>
        <span>Actions</span>
      </div>

      <mat-menu #actionsmenu="matMenu">
        <button *ngIf="AllowCancel" mat-menu-item (click)="btnCancelAbsence()">
          <mat-icon>event_busy</mat-icon>
          <span>Cancel Absence</span>
        </button>
        <button *ngIf="AllowEdit" mat-menu-item (click)="btnEditAbsence()">
          <mat-icon>edit</mat-icon>
          <span>Edit Absence</span>
        </button>
      </mat-menu>


    </div>
  </div>




  <div class="section" style="display: flex; flex-direction: column;">

    <app-stamp [status]=RequestStatusDescription></app-stamp>

  </div>


  <div class="section">
    <div style="display: flex; margin-bottom: 30px; align-items: center; flex-direction: row; flex-wrap: nowrap; align-content: center; justify-content: flex-start;">
      <div style="background-color: {{AbsenceBackColour}}; max-width: 20px; height: 40px; flex: 1; margin-right: 10px;border-radius:6px;"></div>
      <div class="title">{{AbsenceTitle}}</div>
    </div>

    <div class="tabledetails">
      <table>
        <tbody>
          <tr><td>Date Range -</td><td>{{DateRange}}</td></tr>
          <tr><td>Total Hours -</td><td>{{TotalHours}}</td></tr>
        </tbody>
      </table>

      <div *ngIf="isAdmin || isApproverForUser || isViewingUser || isAllowedForExternalWork">
        <div>Notes - {{RequestDescription}}</div>
      </div>

    </div>


  </div>


  <div *ngIf="hasDetail" class="section tabledetails" [innerHTML]="safeTableOfDetails"></div>



  <div class="floatingpanel" *ngIf="!hasApproved && isApproving && (isApproverForUser)" style="display: flex; justify-content: center; flex-direction: column; align-items: center;">

    <div style="width: 100%; display: flex; justify-content: space-evenly; flex-direction: row;">
      <div class="btn clsapprove" (click)="btnApprove()"><span class="material-symbols-outlined">check</span>Approve</div>
      <div [ngClass]="{'decline-btn': RequestStatusID === 3}" class="btn clsdecline {{rclas}}" (click)="btnDecline()"><span class="material-symbols-outlined">close</span>Decline</div>
    </div>

    <mat-form-field style="width:100%;margin-top:10px;" *ngIf="RequestStatusID === 3">
      <mat-label>Response To User</mat-label>
      <input matInput [(ngModel)]="RequestResponseNotes">
    </mat-form-field>
    <button class="decline2" *ngIf="RequestStatusID === 3" (click)="saveData()" [disabled]="!RequestStatusID" mat-stroked-button>Decline</button>
  </div>



</div>




