import { Component } from '@angular/core';

@Component({
    selector: 'app-manageuserstime',
    templateUrl: './manageuserstime.component.html',
    styleUrl: './manageuserstime.component.css',
    standalone: false
})
export class ManageuserstimeComponent {

}
