import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ViewChild, ElementRef,Renderer2 } from '@angular/core';
import { DataService } from '../data.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { RequesttimeComponent } from '../requesttime/requesttime.component';



@Component({
    selector: 'app-sidepanelcontent',
    templateUrl: './sidepanelcontent.component.html',
    styleUrl: './sidepanelcontent.component.css',
    standalone: false
})
export class SidepanelcontentComponent implements OnInit {

  constructor(private dataService: DataService, private cdr: ChangeDetectorRef, public dialog: MatDialog, private renderer: Renderer2, private el: ElementRef, private sanitizer: DomSanitizer) { }


  isBusy: boolean = false;
  hasApproved: boolean = true;

  isApproving: boolean = false;
  dataRequest: any;
  AbsenceTitle: string = "test";
  AbsenceBackColour: string = "";
  AbsenceForeColour: string = "";
  hasDetail: Boolean = false;


  AllowEdit: number = 0;
  AllowCancel: number = 0;
  DisplayName: string = "";
  StartDateTime: string = "";
  EndDateTime: string = "";
  TotalHours: string = "";
  RequestDescription: string = "";
  RequestResponseNotes: string = "";
  RequestStatusID: number = 0;
  RequestStatusDescription: string = "";
  RequestID: number = 0;
  PendingApproverID: number = 0;
  PendingApproverDisplayName: string = "";
  Approver1DisplayName: string = "";
  Approver2DisplayName: string = "";
  RequestResponseNotesApprover1: string = "";
  RequestResponseNotesApprover2: string = "";
  App1Pending: string = "";
  App2Pending: string = "";
  CancelledByUser: string = "";
  isAutoApproved: number = 0;
  Approver1StatusID: number = 0;
  Approver2StatusID: number = 0;


  DateRange: string = "";
  isApproverForUser: boolean = false;
  isAdmin: boolean = false;
  isViewingUser: boolean = false;
  RequestUserID: number = 0;
  AbsenceID: number = 0;
  isAllowedForExternalWork: boolean = false;
  clsdeclineStyles = {};

  TableOfDetails: string="";
  safeTableOfDetails!: SafeHtml;

  ngOnInit(): void {



  }




  saveData(): void {

    const data = {
      RequestID: this.RequestID, RequestStatusID: this.RequestStatusID, RequestResponseNotes: this.RequestResponseNotes
    };

    this.isBusy = true;

    this.dataService.dbAdminSaveRequestResponse(data).subscribe(
      response => {
        this.isBusy = false;
        this.dataService.RefreshPinboard();
        this.dataService.RefreshPendingApprovalsList();
        this.dataService.CloseSidePanel();

      },
      error => {
        console.error('Error:', error);
      }
    );



  }


  selectedButton: number = 0;

  btnApprove(): void {
    this.RequestStatusID = 2;
    this.saveData();
  }


  rclas: string = "";

  btnDecline(): void {
    //change button color to grey
    //change bottom button to red



    this.rclas = 'dec1';

    //const clsdecline = this.el.nativeElement.querySelector('.clsdecline');
    //this.renderer.setStyle(clsdecline, 'background-color', '#444');


    this.RequestStatusID = 3;


    const decline2 = this.el.nativeElement.querySelector('.decline2');
    this.renderer.setStyle(decline2, 'background-color', 'red');





  }

  btnCancelAbsence(): void {
    this.RequestStatusID = 4;
    this.saveData();
  }

  btnEditAbsence(): void {
   // this.RequestStatusID = 4;
    // this.saveData();


    const dialogRef = this.dialog.open(RequesttimeComponent, {
      width: '60%',
      maxWidth: '90vw',
      minWidth: '300px',
      maxHeight: '80vh',
      disableClose: true,
      data: { RequestID: this.RequestID },
      panelClass: 'custom-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
     // this.refreshData();
      console.log('Dialog closed:', result);
    });




  }




  public loadRequest(data: any): void {

    //remove the class ( background #444) from the decline button
    const elements = this.el.nativeElement.querySelectorAll('.dec1');
    elements.forEach((element: HTMLElement) => {
      this.renderer.removeClass(element, 'dec1');
    });



    //clear all fields
    this.App1Pending = "";
    this.App2Pending = "";
    this.RequestResponseNotes = "";

    this.RequestStatusID = 0;
    this.isApproving = data.isApproving;
    this.RequestID = data.requestID;

    console.log("loading : " + data.requestID);

    this.dataService.dbAdminGetRequest(data.requestID).subscribe((response) => {

      this.dataRequest = response.dsData[0];//only one record hence [0]



      this.hasApproved = true;

      if (this.dataRequest.ApprovingRequired == 1) {
        this.hasApproved = false;
      } else {
        this.hasApproved = true;
      };

      this.AllowEdit = this.dataRequest.AllowEdit;
      this.AllowCancel = this.dataRequest.AllowCancel;
      this.DisplayName = this.dataRequest.DisplayName;
      this.StartDateTime = this.dataRequest.StartDateTime;
      this.EndDateTime = this.dataRequest.EndDateTime;
      this.RequestDescription = this.dataRequest.RequestDescription;
      this.AbsenceBackColour = this.dataRequest.AbsenceBackColour;
      this.AbsenceForeColour = this.dataRequest.AbsenceForeColour;
      this.AbsenceTitle = this.dataRequest.AbsenceDescription;
      this.RequestDescription = this.dataRequest.RequestDescription;

      this.PendingApproverID = Number(this.dataRequest.PendingApproverID);
      this.PendingApproverDisplayName = this.dataRequest.PendingApproverDisplayName;
      this.Approver1DisplayName = this.dataRequest.Approver1DisplayName;
      this.Approver2DisplayName = this.dataRequest.Approver2DisplayName;

      this.Approver1StatusID = this.dataRequest.Approver1StatusID;
      this.Approver2StatusID = this.dataRequest.Approver2StatusID;


      this.RequestResponseNotesApprover1 = this.dataRequest.RequestResponseNotesApprover1;
      this.RequestResponseNotesApprover2 = this.dataRequest.RequestResponseNotesApprover2;


      this.AbsenceID = this.dataRequest.AbsenceID;
      this.RequestUserID = this.dataRequest.RequestUserID;

      this.CancelledByUser = this.dataRequest.CancelledByUser;

      this.isAutoApproved = this.dataRequest.isAutoApproved;

      if (this.dataService.GlobalIsAdmin == 0) {
        console.log('admin false');
        this.isAdmin = false;
      } else {
        console.log('admin true');
        this.isAdmin = true;
      }

      this.hasDetail = false;
      this.TableOfDetails = "<table><tbody>"



      //"isAdmin || isApproverForUser || isViewingUser || isAllowedForExternalWork"



      if (this.dataRequest.ManagedUser != '' && (this.isAdmin || this.isApproverForUser || this.isApproving)) {
        
        this.TableOfDetails = this.TableOfDetails + "<tr><td>Submitted by -</td><td>" + this.dataRequest.ManagedUser + "</td></tr>"
        this.hasDetail = true;
      }


      if (this.isAutoApproved == 0) {
        if (this.Approver1DisplayName != '' && this.Approver2DisplayName != '') {
          this.TableOfDetails = this.TableOfDetails + "<tr><td>1st Approver -</td><td>" + this.Approver1DisplayName + ' ' + this.dataRequest.Approver1Status + "<br>" + this.dataRequest.Approver1DateStamp + "</td></tr>"

          if (this.isAdmin || this.isApproverForUser || this.isViewingUser || this.isAllowedForExternalWork) {
            if (this.RequestResponseNotesApprover1 != "") {
              this.TableOfDetails = this.TableOfDetails + "<tr><td colspan=2>Response Notes - " + this.RequestResponseNotesApprover1 + "</td></tr>"
            }
          }


          if (this.dataRequest.Approver1StatusID != 3) { //if approver 1 declined - dont show the approver 2 info

            this.TableOfDetails = this.TableOfDetails + "<tr><td>2nd Approver -</td><td>" + this.Approver2DisplayName + ' ' + this.dataRequest.Approver2Status + "<br>" + this.dataRequest.Approver2DateStamp + "</td></tr>"

            if (this.isAdmin || this.isApproverForUser || this.isViewingUser || this.isAllowedForExternalWork) {
              if (this.RequestResponseNotesApprover2 != "") {
                this.TableOfDetails = this.TableOfDetails + "<tr><td colspan=2>Response Notes - " + this.RequestResponseNotesApprover2 + "</td></tr>"
              }
            }

          }
          


          this.hasDetail = true;
        }



        //	RequestResponseNotes


        if (this.Approver1DisplayName != '' && this.Approver2DisplayName == '') {
          this.TableOfDetails = this.TableOfDetails + "<tr><td></td><td>Approver -</td><td>" + this.Approver1DisplayName + ' ' + this.dataRequest.Approver1Status + "</td></tr>"
          this.hasDetail = true;
        }

      } else {
        this.TableOfDetails = this.TableOfDetails + "<tr><td>Auto Approved -</td><td>" + this.dataRequest.RequestDateAdded + "</td></tr>"
        this.hasDetail = true;
      }



      if (this.CancelledByUser != '') {
        this.TableOfDetails = this.TableOfDetails + "<tr><td>Cancelled by -</td><td>" + this.CancelledByUser + "<br>" + this.dataRequest.CancelledDateTime + "</td></tr>"
        this.hasDetail = true;
      }


      this.TableOfDetails = this.TableOfDetails + "</tbody></table>"
      this.hasDetail = true;


      this.safeTableOfDetails = this.sanitizer.bypassSecurityTrustHtml(this.TableOfDetails);



      this.cdr.detectChanges();




      if (this.RequestUserID == this.dataService.GlobalUserID) {
        this.isViewingUser = true
      } else {
        this.isViewingUser = false
      }


    
      this.isAllowedForExternalWork = false;
      //hardcoded...
      if (this.AbsenceID == 8) {
        if (this.dataService.GlobalUserID == 80 || this.dataService.GlobalUserID == 12 || this.dataService.GlobalUserID == 81 || this.dataService.GlobalUserID == 77) {
          this.isAllowedForExternalWork = true;
        }
      }





      //show to apporve panel if the approving user pending and allowed
      if (this.dataService.GlobalUserID == this.dataRequest.PendingApproverID) {
        this.isApproverForUser = true;
      } else {
        this.isApproverForUser = false;
      }



      const sdate = moment(this.dataRequest.StartDateTime, 'DD MMM YYYY HH:mm:ss');
      const edate = moment(this.dataRequest.EndDateTime, 'DD MMM YYYY HH:mm:ss');
      this.DateRange = sdate.format('D MMM YYYY (h:mma)') + ' - ' + edate.format('D MMM YYYY (h:mma)');
      this.TotalHours = this.dataRequest.TotalHours + ' hours';

      this.RequestStatusDescription = this.dataRequest.RequestStatusDescription;

      this.cdr.detectChanges();
   
    });




  }



}
