




<div #tooltipContainer></div>

  <div id="pinboardHeader">
    <div id="pnlcontrols" style="align-items: center; overflow: hidden; align-items: center; ">
      <div style="display: flex; align-items: center;gap:10px;">
        <mat-form-field style="width: 130px; margin-top: 22px; margin-left: -3px;">
          
          <mat-select [(value)]="selectedDateScope" (selectionChange)="onSelectedDateScope($event)">
            <mat-option value="week">Week</mat-option>
            <mat-option value="2weeks">2 Weeks</mat-option>
            <mat-option value="month">Month</mat-option>
          </mat-select>
        </mat-form-field>

        <div id="monthnav" style="display: flex; align-items: center; justify-content: center; gap: 10px;">

          <button class="btn" (click)="btnPrevScope()"  [disabled]="isButtonPrevDisabled">
            <span class="material-symbols-outlined">arrow_back_ios_new</span>
          </button>

          <div style=" display: inline-block; vertical-align: top; min-width: 120px; text-align: center;">

            <div *ngIf="selectedDateScope!='month'">{{ DateScopeDisplay }}</div>

            <div style="width:150px;" *ngIf="selectedDateScope=='month' && inMonthList==false">{{ DateScopeDisplay }}</div>


            <mat-form-field *ngIf="selectedDateScope=='month' && inMonthList" style="display: flex; width: fit-content;margin-top:21px;" class="selectdate" appearance="outline">
              <mat-select [(value)]="DateScopeDisplay" (selectionChange)="onMonthChanged($event)">
                <mat-option *ngFor="let option of dataMonths" [value]="option.MonthName">
                  {{option.MonthName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div> 

           
          
          <button class="btn" (click)="btnNextScope()" [disabled]="isButtonNextDisabled">
            <span class="material-symbols-outlined">arrow_forward_ios</span>
          </button>
        </div>




        <div  (click)="btnToday()" class="btn">
          Today
        </div>

      </div>
    </div>
  </div>


  <div class="scrollable-table">
    <table id="mytable" mat-table [dataSource]="rowData" class="mat-elevation-z8">

      <ng-container matColumnDef="Name">
        <th class="nameheader sticky-header" mat-header-cell *matHeaderCellDef> Employees </th>
        <td class="namecontent" [ngClass]="{'isadmin': isAdmin == 1}" (click)="isAdmin == 1 ? showUserDashboard(row?.UserID) : null" class="namecell" mat-cell *matCellDef="let row">{{row?.DisplayName }} </td>
      </ng-container>

      <ng-container *ngFor="let column of displayedColumns.slice(1)" [matColumnDef]="column">
        <th class="sticky-header" id="a{{ column | date: 'ddMMMyyyy' }}" mat-header-cell *matHeaderCellDef>{{ column | date:'dd' }}<br />{{ column | date: 'EEE' }} </th>
        <td [ngClass]="{'weekend-cell': row.UserRow[displayedColumns.indexOf(column) - 1]?.isWeekend,'bh-cell': row.UserRow[displayedColumns.indexOf(column) - 1]?.isBankHoliday}" mat-cell *matCellDef="let row" style="padding: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <!-- Cell content goes here (e.g., data or other components) -->
        </td>
      </ng-container>

      <!-- Header and Rows -->
      <tr class='sticky' mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr style="height:{{row.rowheight}}px!important;" [id]="row.UserID"  [ngClass]="{'sticky': row?.UserID == 'UserID9000'}" [ngStyle]="{'top' : row?.UserID == 'UserID9000' ? '51px' : '0px'}" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>




