import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MaintenanceService } from './services/maintenance.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
    standalone: false
})
export class AppComponent {
  title = 'Core';

  constructor(private location: Location, private maintenanceService: MaintenanceService) { }

  ngOnInit() {

    this.maintenanceService.startCheckingMaintenanceMode();



    if (location.protocol !== 'https:' && location.hostname !== 'localhost') {
      window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }



  }




}
