import { NgModule, provideZoneChangeDetection } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Layoutv1Component } from './layoutv1/layoutv1.component';
import { MytimeComponent } from './mytime/mytime.component';
import { RequesttimeComponent } from './requesttime/requesttime.component';
import { PeoplestimeComponent } from './peoplestime/peoplestime.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'; // Import MatNativeDateModule for native date support
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { provideHttpClient, HTTP_INTERCEPTORS, withInterceptorsFromDi } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { FormsModule } from '@angular/forms';  // Import FormsModule
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsermaintenanceComponent } from './usermaintenance/usermaintenance.component';
import { PendingapprovalsComponent } from './pendingapprovals/pendingapprovals.component'; // Import ReactiveFormsModule
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DlgUserComponent } from './dlg-user/dlg-user.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DlgApprovalComponent } from './dlg-approval/dlg-approval.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerToggle } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list'; // Import the Grid List module
import { DatePipe } from '@angular/common';
import { PinboardComponent } from './pinboard/pinboard.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTooltip } from '@angular/material/tooltip';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { BalancesComponent } from './balances/balances.component';
import { MenusmallComponent } from './menusmall/menusmall.component';
import { MatMenuModule } from '@angular/material/menu';
import { MenuComponent } from './menu/menu.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RequestsComponent } from './requests/requests.component';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { PendingapprovallistComponent } from './pendingapprovallist/pendingapprovallist.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSidenav } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { CompanydocumentsComponent } from './companydocuments/companydocuments.component';
import { ManageuserstimeComponent } from './manageuserstime/manageuserstime.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { RouterModule, provideRouter } from '@angular/router';
import { SidepanelcontentComponent } from './sidepanelcontent/sidepanelcontent.component';
import { StampComponent } from './stamp/stamp.component';
import { RequestsentComponent } from './requestsent/requestsent.component';
import { RequestchangeComponent } from './requestchange/requestchange.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BottomsheetComponent } from './bottomsheet/bottomsheet.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD MMM YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    Layoutv1Component,
    MytimeComponent,
    RequesttimeComponent,
    PeoplestimeComponent,
    LoginComponent,
    DashboardComponent,
    UsermaintenanceComponent,
    PendingapprovalsComponent,
    DlgUserComponent,
    DlgApprovalComponent,
    PinboardComponent,
    ForgotpasswordComponent,
    BalancesComponent,
    MenusmallComponent,
    MenuComponent,
    RequestsComponent,
    PendingapprovallistComponent,
    TooltipComponent,
    CompanydocumentsComponent,
    ManageuserstimeComponent,
    SidepanelcontentComponent,
    StampComponent,
    RequestsentComponent,
    RequestchangeComponent,
    BottomsheetComponent,
    MaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatMomentDateModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatGridListModule,
    DatePipe,
    MatButtonModule,
    MatTooltipModule,
    MatTooltip,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule,
    MatAccordion,
    MatExpansionModule,
    MatChipsModule,
    MatSidenavModule,
    MatSidenav,
    MatToolbarModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,

  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
