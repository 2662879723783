import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { interval, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  private maintenanceUrl = 'https://devapi.asbcore.co.uk/api/maintenance/maintenance-status'; // API endpoint for the status

  constructor(private http: HttpClient, private router: Router) { }

  maintenancestate = false;

  startCheckingMaintenanceMode() {

   this.http.get<{ maintenanceMode: boolean }>(this.maintenanceUrl).subscribe(
      (response) => {
        if (response && response.maintenanceMode) {
          this.router.navigate(['/maintenance']);
        }
      },
      (error) => {
        console.error("API Error during initial check:", error);
      }
   );

    interval(10000) // Check every 10 seconds
    .pipe(
        switchMap(() =>
          this.http.get<{ maintenanceMode: boolean }>(this.maintenanceUrl).pipe(
            catchError((error) => {
              console.error("API Error:", error);
              return of(null); // Handle errors gracefully
            })
          )
        )
      )
      .subscribe((response) => {
        //console.log('check' + response!.maintenanceMode);
        if (response && response.maintenanceMode) {
            this.router.navigate(['/maintenance']);
        }
      });
  }


}

