import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../data.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dlg-approval',
    templateUrl: './dlg-approval.component.html',
    styleUrl: './dlg-approval.component.css',
    standalone: false
})
export class DlgApprovalComponent implements OnInit {


  constructor(public dialogRef: MatDialog, private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) { }

  dataRequest: any;
  AbsenceTitle: string = "test";
  AbsenceBackColour: string = "";
  AbsenceForeColour: string = "";


  DisplayName: string = "";
  StartDateTime: string = "";
  EndDateTime: string = "";
  TotalHours: number = 0;
  RequestDescription: string = "";
  RequestResponseNotes: string = "";
  RequestStatusID: number = 0;



  ngOnInit(): void {


    console.log(this.data.RequestID);

    this.dataService.dbAdminGetRequest(this.data.RequestID).subscribe((response) => {
      this.dataRequest = response.dsData[0];//only one record hence [0]

      this.DisplayName = this.dataRequest.DisplayName;
      this.StartDateTime = this.dataRequest.StartDateTime;
      this.EndDateTime = this.dataRequest.EndDateTime;
      this.TotalHours = this.dataRequest.TotalHours;
      this.RequestDescription = this.dataRequest.RequestDescription;
      this.AbsenceBackColour = this.dataRequest.AbsenceBackColour;
      this.AbsenceForeColour = this.dataRequest.AbsenceForeColour;
      this.AbsenceTitle = this.dataRequest.AbsenceDescription;

   });




  }



  saveData(): void {

    const data = {
      RequestID: this.data.RequestID, RequestStatusID: this.RequestStatusID, RequestResponseNotes: this.RequestResponseNotes
    };


    console.log(data);
    this.dataService.dbAdminSaveRequestResponse(data).subscribe(
      response => {
        console.log('saved!');

         //this.router.navigate(['/dashboard'], { fragment: 'pendingapprovals' });
      },
      error => {
        console.error('Error:', error);
      }
    );

    this.dialogRef.closeAll();

  }





}
