import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';  // Import your AuthService
import { environment } from '../../environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {


  private socket: WebSocket | null = null;



  constructor(private authService: AuthService) {
    this.connect2(environment.WebSocketServer);
    console.log(environment.WebSocketServer);
  }

  connect2(url: string): void {
    this.socket = new WebSocket(url);

    this.socket.onopen = (event) => {
      console.log('WebSocket connection opened:', event);
    };

    this.socket.onmessage = (event) => {
      console.log('Message from server1!:', event.data);



      const data = JSON.parse(event.data);


      console.log('Message from server2!:', data.Param2);



      if (data.Param1 == 'BroadLogout') {
        this.authService.logout().subscribe({
          next: () => window.location.href = '/index.html'
        });
      }
    };

    this.socket.onerror = (event) => {
      console.error('WebSocket error:', event);
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
    };
  }

  sendMessage(message: any): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open');
    }
  }

  closeConnection(): void {
    if (this.socket) {
      this.socket.close();
    }
  }



  









}
