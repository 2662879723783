import { Component, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PinboardComponent } from '../pinboard/pinboard.component';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-pendingapprovals',
    templateUrl: './pendingapprovals.component.html',
    styleUrl: './pendingapprovals.component.css',
    standalone: false
})



export class PendingapprovalsComponent  {
  @ViewChild(PinboardComponent) pinboardComponent!: PinboardComponent;


  urlPATab: string = "";
  selectedTabIndex: number = 0;



  constructor(private route: ActivatedRoute) { }





  ngOnInit(): void {


    this.route.queryParamMap.subscribe(params => {
      this.urlPATab = params.get('patab') ?? '';

      if (this.urlPATab == '' || this.urlPATab == 'pendinglist') {
        this.selectedTabIndex = 0;
      }

      if (this.urlPATab == 'calendar') {
        this.selectedTabIndex = 1;
      }
    });


  }


  onTabChange(event: MatTabChangeEvent): void {
  //  //make sure all tooltips are removed when switching tabs
     this.pinboardComponent.removeAllInstances('app-tooltip');
  }






}

