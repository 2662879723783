  <div class="outer">
    <!-- Header -->

    <header style="background:{{headercolor}};display: flex; align-items: center; flex-direction: row; justify-content: space-between; ">
      <div style="display: flex; align-items: center; justify-content: flex-start; flex-wrap: nowrap; flex-direction: row;">
        <div class="smallmenu"><app-menusmall></app-menusmall></div>
        <div class="logo"><img src="/assets/images/alsimcore.png"></div>
      </div>
      <div style=" display: flex; justify-content: flex-end; flex-direction: row; align-items: center;">
        <div style="margin-right:10px;"></div>

        <div class="usermenu" [matMenuTriggerFor]="usermenu">{{DisplayName}}</div>
        <mat-menu #usermenu="matMenu">
          <button mat-menu-item (click)="Logout()">
            <mat-icon>logout</mat-icon>
            <span>Sign Out</span>
          </button>
        </mat-menu>
      </div>



    </header>





    <!-- Main container (sidebar + content) -->
    <div class="container">


      <mat-sidenav-container class="menusidenav-container">

        <mat-sidenav mode="side"
                     opened
                     disableClose
                     class="menusidenav"
                     [ngClass]="{ 'collapsed': !isHovered }"
                     (mouseenter)="isHovered = true"
                     (mouseleave)="isHovered = false">


          <mat-nav-list id="menu">

            <mat-list-item (click)="isHovered = false;closeSidenav()" class="custom-hover" [routerLink]="['/dashboard']" [fragment]="'mytime'">
              <mat-icon>event_available</mat-icon>
              <span class="menu-text">My Time Off</span>
            </mat-list-item>

            <mat-list-item (click)="isHovered = false;closeSidenav()" class="custom-hover" [routerLink]="['/dashboard']" [fragment]="'requesttime'">
              <mat-icon>schedule</mat-icon>
              <span class="menu-text">Request or Report Time Off</span>
            </mat-list-item>

            <mat-list-item (click)="isHovered = false;closeSidenav()" class="custom-hover" [routerLink]="['/dashboard']" [fragment]="'peoplestime'">
              <mat-icon class="material-symbols-outlined">calendar_clock</mat-icon>
              <span class="menu-text">Calendar View</span>
            </mat-list-item>


            <mat-list-item (click)="isHovered = false;closeSidenav()" class="custom-hover" (click)="Logout()">
              <mat-icon>logout</mat-icon>
              <span class="menu-text">Sign Out</span>
            </mat-list-item>

            <div *ngIf="IsAdmin == 1 || IsApprover == 1">
              <hr />

              <mat-list-item *ngIf="IsAdmin == 1" (click)="isHovered = false;closeSidenav()" class="custom-hover" [routerLink]="['/dashboard']" [fragment]="'usermaintenance'">
                <mat-icon>group</mat-icon>
                <span class="menu-text">Users</span>
              </mat-list-item>

              <mat-list-item *ngIf="IsAdmin == 1 || IsApprover == 1" (click)="isHovered = false;closeSidenav()" class="custom-hover" [routerLink]="['/dashboard']" [fragment]="'pendingapprovals'">
                <mat-icon>date_range</mat-icon>
                <span class="menu-text">Pending Approvals</span>
              </mat-list-item>

              <mat-list-item *ngIf="IsAdmin == 1" (click)="isHovered = false;closeSidenav()" class="custom-hover" [routerLink]="['/dashboard']" [fragment]="'manageuserstime'">
                <mat-icon>manage_accounts</mat-icon>
                <span class="menu-text">Manage Absence Requests</span>
              </mat-list-item>

            </div>

          </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content class="sidenavcontent">

          <app-pendingapprovals class="layout" *ngIf="menuOption == 'pendingapprovals'"></app-pendingapprovals>
          <app-mytime class="layout" *ngIf="menuOption == 'mytime'"></app-mytime>
          <app-requesttime class="layout" *ngIf="menuOption == 'requesttime'"></app-requesttime>
          <app-peoplestime class="layout" *ngIf="menuOption == 'peoplestime'"></app-peoplestime>
          <app-usermaintenance class="layout" *ngIf="menuOption == 'usermaintenance'"></app-usermaintenance>
          <app-companydocuments class="layout" *ngIf="menuOption == 'companydocuments'"></app-companydocuments>
          <app-requesttime [isManaging]=true class="layout" *ngIf="menuOption == 'manageuserstime'"></app-requesttime>


          <mat-sidenav-container class="popsidenav-container">

            <mat-sidenav #sidenav mode="side" class="popsidenav">
              <div style=" display: flex; flex-direction: row; justify-content: flex-end; align-items: flex-start;background-color:white;">
                <button (click)="closeSidenav()" mat-icon-button aria-label="close dialog" mat-dialog-close>
                  <mat-icon>close</mat-icon>
                </button>
              </div>

              <app-sidepanelcontent></app-sidepanelcontent>

            </mat-sidenav>

            <mat-sidenav-content></mat-sidenav-content>

          </mat-sidenav-container>

        </mat-sidenav-content>

      </mat-sidenav-container>



      <!--


                <div (mouseenter)="sidenav.open()" (mouseleave)="sidenav.close()" style="background-color:#444;">
          here

        </div>
      <mat-sidenav-container>

        <mat-sidenav #sidenav mode="over" [(opened)]="opened" style="width:200px;" (mouseenter)="sidenav.open()" (mouseleave)="sidenav.close()">


          <app-menu class="nav"></app-menu>
          </mat-sidenav>

          <mat-sidenav-content style="width:100%;">

            <app-pendingapprovals class="layout" *ngIf="menuOption == 'pendingapprovals'"></app-pendingapprovals>
            <app-mytime class="layout" *ngIf="menuOption == 'mytime'"></app-mytime>
            <app-requesttime class="layout" *ngIf="menuOption == 'requesttime'"></app-requesttime>
            <app-peoplestime class="layout" *ngIf="menuOption == 'peoplestime'"></app-peoplestime>
            <app-usermaintenance class="layout" *ngIf="menuOption == 'usermaintenance'"></app-usermaintenance>



          </mat-sidenav-content>

          </mat-sidenav-container>
          -->







    </div>
  </div>

